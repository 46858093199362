<template>
    <section class="compras-ver-factura px-3">
        <div class="row my-4">
            <div class="col-md-12 col-lg-10">
                <div class="bg-f5 border br-5 p-2">
                    <div class="row mx-0">
                        <div class="col-12 f-18 f-700 col-md-auto"> Factura {{ listFactura.referencia }} </div>
                        <div class="col-12 f-18 f-700 col-md-auto ml-auto"> {{ formatoMoneda(listFactura.valor) }}</div>
                        <div class="col-12 my-1">
                            <div class="d-middle">
                                <i :class="`${status}`" class="f-18 text-pendiente" />
                                <p class="f-12">{{listFactura.facturas_estado && listFactura.facturas_estado.estado}}</p>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="f-600 f-14">
                                Fecha de útlimo pago:
                                <span class="f-300">{{listFactura.fecha_pago | helper-fecha('DD MMM YYYY')}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <titulo-divisor titulo="Ordenes de compra">
            <div class="row">
                <div class="col auto">
                    <input-search 
                    v-model="query" 
                    placeholder="Buscar"  
                    :search="beforeSearch" 
                    auto-search 
                    />
                </div>
                <filter-indicator :count="filterCount" @openModalFilter="abrirModalFiltros" @clearFilter="clearFilter"/>
            </div>
        </titulo-divisor>
        <!-- listado ordenes compra -->
        <div class="row mt-5">
            <div v-for="data in listOrdenesCompras" :key="data.id" class="col-12 col-md-6 col-lg-auto mb-4">
                <card-orden-compra
                :orden="data.id"
                :estado="data.id_estado"
                :proveedor="data.proveedor"
                :cant="data.numero_materiales"
                :valor="data.valor"
                :cliente="data.cliente"
                @verOrden="verOrden(data.id)"
                />
            </div>

        </div>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proyecto</p>
                    <el-select v-model="filter.id_oportunidad" placeholder="Seleccionar proyecto" size="small" class="w-100">
                        <el-option v-for="item in filtro_oportunidades" :key="`op-${item.id}`" :label="item.nombre" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Proveedores</p>
                    <el-select v-model="filter.id_proveedor" placeholder="Seleccionar proveedor" size="small" class="w-100" multiple>
                        <el-option v-for="item in filtro_proveedores" :key="`pv-${item.id}`" :label="item.proveedor" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Cliente</p>
                    <el-select v-model="filter.id_cliente" placeholder="Seleccionar cliente" size="small" class="w-100">
                        <el-option v-for="(item, index) in filtro_clientes" :key="`c-${index}`" :label="item.nombre_corto" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-6 mb-4">
                    <p class="input-label-top">Fecha de inicio</p>
                    <el-date-picker v-model="filter.fecha_inicio" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-6 mb-4">
                    <p class="input-label-top">Fecha final</p>
                    <el-date-picker v-model="filter.fecha_fin" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div class="col-12 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="value_range" range :max="rango_maximo" :format-tooltip="formatTooltipRange" :step="1000"/>
                </div>
                <div class="col-12 mb-4">
                    <p class="input-label-top">Estado</p>
                    <el-select v-model="filter.id_estado" placeholder="Seleccionar estado" size="small" class="w-100">
                        <el-option v-for="item in id_estados" :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="filtrarOrdenes"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components:{
        cardOrdenCompra: () => import('../components/cardOrdenCompra.vue')
    },
    async mounted() {
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['compras.matrix'])
        Promise.all([
            this.obtenerFiltroProveedores(),
            this.obtenerFiltroOportunidades(),
            this.obtenerFiltroClientes(),
        ])
        await this.obtenerRangoMaximo(this.id_factura)
        this.clearFilter()
        this.getOrdenesCompras(this.id_factura)
        await this.getFactura(this.id_factura)
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs', [
            {
                position: 1,
                breadcumb: {
                    name: `Factura ${this.listFactura.referencia}`,
                    route: 'orden.compra.facturas.ver',
                    params: {
                        id : this.listFactura.id,
                        id_factura: this.id_factura
                    }
                }
            }
        ])
        
    },
    computed: {
        ...mapGetters({
            listFactura: 'compras/facturas/factura',
            listOrdenesCompras: 'compras/facturas/ordenes_compra',
            filtro_proveedores: 'compras/facturas/filtro_proveedores',
            filtro_oportunidades: 'compras/facturas/filtro_oportunidades',
            filtro_clientes: 'compras/facturas/filtro_clientes',
            rango_maximo: 'compras/facturas/rango_maximo',
            estados: 'compras/facturas/estados',
            id_estados: 'compras/facturas/id_estados',
        }),
        filter: {
            get(){
                return this.$store.getters['compras/facturas/filter']
            }, 
            set(val){
                this.$store.commit('compras/facturas/set_filter', val)
            }
        },
        query: {
            get(){
                return this.$store.getters['compras/facturas/query']
            }, 
            set(val){
                this.$store.commit('compras/facturas/set_query', val)
            }
        },
        value_range: {
            get(){
                return [
                    this.filter.valor_inicial,
                    this.filter.valor_final
                ]
            },
            set(val){
                this.filter.valor_inicial = val[0]
                this.filter.valor_final = val[1]
            }
        },
        filterCount(){
            return this.filter.count()
        },
        status(){
            return this.estados[this.listFactura.id_estado]
        },
        id_factura(){
            return this.$route.params.id_factura
        }
    },
    methods:{
        ...mapActions({
            getFactura: 'compras/facturas/Action_get_factura',
            getOrdenesCompras: 'compras/facturas/Action_get_ordenes_compra',
            obtenerFiltroProveedores: 'compras/facturas/Action_get_filtro_proveedores',
            obtenerFiltroOportunidades: 'compras/facturas/Action_get_filtro_oportunidades',
            obtenerFiltroClientes: 'compras/facturas/Action_get_filtro_clientes',
            obtenerRangoMaximo: 'compras/facturas/Action_get_rango_maximo',
            clearFilter: 'compras/facturas/clear_filter',
        }),
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        async beforeSearch(){
            this.getOrdenesCompras(this.id_factura)
        },
        async filtrarOrdenes(){
            await this.getOrdenesCompras(this.id_factura)
            this.$refs.abrirModalFiltros.toggle()
        },
        verOrden(id){
            this.$router.push({ name: 'compras.ver.orden', params: { id_orden : id } })
        }

    }
}
</script>

<style lang="scss" scoped>
.compras-ver-factura{
    background:white;
    .icon-history{
        &::before{
            margin-left: 0px;
        }
    }
}
</style>